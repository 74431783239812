<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <div class="table-operations">
        <a-button @click="add" type="primary">添加</a-button>
        <a-button @click="remove" type="danger" :disabled="!this.checkList.length">删除</a-button>
        <a-button @click="getList">刷新</a-button>

        <a-button-group>
          <a-button @click="selectAll">全选</a-button>
          <a-button @click="unselectAll">全不选</a-button>
          <a-button @click="reverseSelect">反选</a-button>
        </a-button-group>

        <span style="margin-left: 10px">共计 {{ list.length }} 个, 已选 {{ checkList.length }} 个</span>
      </div>
      <div class="avatar-container">
        <div class="avatar-item" v-for="avatar in list">
          <a-checkbox v-model="avatar.check"></a-checkbox>
          <a-avatar :size="128" icon="user" :src="avatar.imgUrl" @click="avatar.check = !avatar.check" />
          <a>
            {{avatar.genderDesc}}
          </a>
        </div>
      </div>
    </a-card>
    <create-form ref="createForm" @ok="getList"></create-form>
  </page-header-wrapper>
</template>

<script>
import { listAvatar, delAvatar } from '@/api/config/avatar'
import CreateForm from './modules/CreateForm'
import { tableMixin } from '@/store/table-mixin'

export default {
  name: 'Avatar',
  components: {
    CreateForm
  },
  mixins: [tableMixin],
  data() {
    return {
      list: [],
    }
  },
  created() {
    this.getList()
  },

  methods: {
    /** 查询随机用户头像列表 */
    getList() {
      this.loading = true
      listAvatar(this.queryParam).then(response => {
        this.list = response.data.map(item => {
          return {
            id: item.id,
            imgUrl: item.imgUrl,
            gender: item.gender,
            genderDesc: item.genderDesc,
            check: false
          }
        });
        this.loading = false
      })
    },
    // 上传
    add() {
      this.$refs.createForm.handleAdd();
    },
    // 删除
    remove() {
      this.$confirm({
        title: '确认删除所选中头像?',
        content: `确认删除所选中的 ${this.checkList.length} 个头像吗?`,
        onOk: () => {
          return delAvatar(this.checkList.map(item => item.id))
            .then(() => {
              this.getList()
              this.$message.success('删除成功')
            })
        },
      })
    },
    // 全选
    selectAll() {
      this.list.forEach(item => item.check = true);
    },
    // 全不选
    unselectAll() {
      this.list.forEach(item => item.check = false);
    },
    // 反选
    reverseSelect() {
      this.list.forEach(item => item.check = !item.check);
    }
  },
  computed: {
    checkList() {
      return this.list.filter(item => item.check);
    }
  }
}
</script>
<style>
.avatar-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 40px;
}

.avatar-item {
  position: relative;
  margin: 20px;
  cursor: pointer;
  transition: all .3s;
}

.avatar-item:hover {
  transform: scale(1.5);
  z-index: 10;
}

.avatar-item .ant-checkbox-wrapper {
  position: absolute;
}

.avatar-item img {
  pointer-events: none;
  user-select: none;
}
</style>